<template>
  <el-card class="box-card">
    <div class="indicator-container">
      <i class="indicator-icon" :class="'el-icon-' + icon" v-if="icon" />
      <span class="indicator-number">
        {{ number }}
      </span>
      <div class="indicator-subnumber-container">
        <span
          class="indicator-subnumber"
          v-for="item in subNumber"
          :key="item.icon"
          :title="item.icon">
          <i class="el-icon-warning-outline" v-if="item.icon === 'frozen'"></i>
          <i class="el-icon-circle-check" v-else></i>

          {{ item.count }}
        </span>
      </div>

      <span class="indicator-title">
        {{ title }}
      </span>

      <span
        v-if="subtitle"
        class="indicator-subtitle"
        v-html="subtitle" />
    </div>
  </el-card>
</template>
<script>
export default {
  name: 'Indicator',
  props: [
    'icon',
    'title',
    'subtitle',
    'number',
    'subNumber'
  ]
}
</script>
<style lang="sass">
.indicator-container
  span
    display: block
    text-align: center

  .indicator-icon
    display: block
    font-size: 38px
    text-align: center

  .indicator-number
    font-size: 36px
    font-weight: bold

  .indicator-subtitle
    font-size: .7rem

  .indicator-subnumber-container
    display: flex
    flex-direction: row
    justify-content: space-between

  .indicator-subnumber
    cursor: pointer
    display: inline-block
    font-size: .8rem
    font-weight: bold
    text-align: center
    width: 50%

</style>
