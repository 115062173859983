<template>
  <el-card class="box-card">
    <div class="indicator-container">
      <i class="indicator-icon" :class="'el-icon-' + icon" v-if="icon" />
      <span class="indicator-number">
        {{ number }}
      </span>

      <span class="indicator-title">
        {{ title }}
      </span>
      <span
        v-if="subtitle"
        class="indicator-subtitle"
        v-html="subtitle" />
    </div>
  </el-card>
</template>
<script>
export default {
  name: 'Indicator',
  props: [
    'icon',
    'title',
    'subtitle',
    'number',
    'subNumber'
  ]
}
</script>
<style lang="sass">
.indicator-container
  span
    display: block
    text-align: center

  .indicator-icon
    display: block
    font-size: 38px
    text-align: center

  .indicator-number
    font-size: 36px
    font-weight: bold

  .indicator-subtitle
    font-size: .7rem

</style>
