<template>
  <div id="home-container">
    <el-row type="flex" justify="space-between">
      <el-col :xl="3" :lg="3" :md="12" :sm="24" :xs="24">
        <indicator-advance
          icon="coin"
          title="Token Twitter"
          :subtitle="stats.tokens.updatedAt"
          :number="stats.tokens.count"
          :sub-number="stats.tokens.subNumber" />
      </el-col>
      <el-col :xl="3" :lg="3" :md="12" :sm="24" :xs="24">
        <indicator
          icon="coin"
          title="Sesiones LinkedIn"
          :subtitle="stats.sessions.updatedAt"
          :number="stats.sessions.count"/>
      </el-col>
      <el-col :xl="3" :lg="3" :md="12" :sm="24" :xs="24">
        <indicator-advance
          icon="coin"
          title="Token TikTok"
          :subtitle="stats.tiktok.updatedAt"
          :number="stats.tiktok.count"
          :sub-number="stats.tiktok.subNumber" />
      </el-col>
      <el-col :xl="3" :lg="3" :md="12" :sm="24" :xs="24">
        <indicator
          icon="user"
          title="Clientes Activos"
          :subtitle="stats.clients.updatedAt"
          :number="stats.clients.count"/>
      </el-col>
      <el-col :xl="3" :lg="3" :md="12" :sm="24" :xs="24">
        <indicator
          icon="setting"
          title="Proyectos Activos"
          :subtitle="stats.projects.updatedAt"
          :number="stats.projects.count"/>
      </el-col>
      <el-col :xl="3" :lg="3" :md="12" :sm="24" :xs="24">
        <indicator
          icon="set-up"
          title="Keywords Activas"
          :subtitle="stats.keywords.updatedAt"
          :number="stats.keywords.count"/>
      </el-col>
    </el-row>

    <el-row type="flex" justify="space-between" :gutter="20">
      <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        <h3 class="text-center">Últimos Inicios de Sesión <small v-html="stats.login.updatedAt"></small></h3>
        <el-table
        :data="stats.login.data"
        stripe
        style="width: 100%">
          <el-table-column
            :label="$t('view.home.lastLogin.name')">
              <template slot-scope="scope">
                  {{ scope.row.firstName }} {{ scope.row.lastName }} ({{ scope.row.username }})
              </template>
          </el-table-column>
          <el-table-column
            prop="client.name"
            :label="$t('view.home.lastLogin.client')"
            width="220">
          </el-table-column>
          <el-table-column
            :label="$t('view.home.lastLogin.date')"
            width="150">
              <template slot-scope="scope">
                {{ scope.row.lastLogin | DatetimeFrom(true) }}
              </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        <h3 class="text-center">Últimos Proyectos Creados <small v-html="stats.created.updatedAt"></small></h3>
        <el-table
        :data="stats.created.data"
        stripe
        style="width: 100%">
          <el-table-column
            prop="id"
            :label="$t('view.home.projects.id')"
            width="80"></el-table-column>
          <el-table-column
            prop="name"
            :label="$t('view.home.projects.name')"></el-table-column>
          <el-table-column
            prop="client.name"
            :label="$t('view.home.projects.client')"></el-table-column>
          <el-table-column
            :label="$t('view.home.projects.date')"
            width="150">
              <template slot-scope="scope">
                {{ scope.row.createdAt | DatetimeFrom(true) }}
              </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <div class="floating-container" :class="{ 'floating-open': floatingMenuState }">
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="floating-button floating-button-open"
        circle
        @click="floatingMenuState = !floatingMenuState"></el-button>

      <el-button
        type="primary"
        class="floating-button floating-button-icon"
        circle
        @click="dialogTwitterState = true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0 0 30 30">
            <path
              fill="#FFFFFF"
              d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
          </svg>
      </el-button>

      <el-button
        type="primary"
        class="floating-button floating-button-icon"
        circle
        @click="dialogLinkedinState = true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 48 48">
              <path
                fill="#FFFFFF"
                d="M36.5,6h-25C8.468,6,6,8.468,6,11.5v25c0,3.032,2.468,5.5,5.5,5.5h25c3.032,0,5.5-2.468,5.5-5.5v-25 C42,8.468,39.532,6,36.5,6z M18,34c0,0.553-0.447,1-1,1h-3c-0.553,0-1-0.447-1-1V21c0-0.553,0.447-1,1-1h3c0.553,0,1,0.447,1,1V34z M15.5,18c-1.381,0-2.5-1.119-2.5-2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C18,16.881,16.881,18,15.5,18z M35,34 c0,0.553-0.447,1-1,1h-3c-0.553,0-1-0.447-1-1v-7.5c0-1.379-1.121-2.5-2.5-2.5S25,25.121,25,26.5V34c0,0.553-0.447,1-1,1h-3 c-0.553,0-1-0.447-1-1V21c0-0.553,0.447-1,1-1h3c0.553,0,1,0.447,1,1v0.541C26.063,20.586,27.462,20,29,20c3.309,0,6,2.691,6,6V34z"></path>
            </svg>
      </el-button>

      <el-button
        type="primary"
        class="floating-button floating-button-icon"
        circle
        @click="dialogTiktokState = true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 0 448 512">
          <path
            fill="#FFFFFF"
            d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
        </svg>
      </el-button>
    </div>

    <el-dialog title="Agregar una nueva sesión de LinkedIn" :visible.sync="dialogLinkedinState" @close="closeModal('Linkedin')">
      <el-form :model="formLinkedin">
        <el-alert
          v-if="formLinkedin.alert"
          :title="formLinkedin.alert.text"
          :type="formLinkedin.alert.type"
          :closable="false">
        </el-alert>

        <el-form-item label="Texto">
          <el-input
            type="textarea"
            :rows="6"
            v-model="formLinkedin.plainSession"
            autocomplete="off" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal('Linkedin')">Cerrar</el-button>
        <el-button type="primary" @click="saveSession('Linkedin')">Guardar</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Agregar una nueva sesión de Twitter" :visible.sync="dialogTwitterState" @close="closeModal('Twitter')">
      <el-form :model="formTwitter">
        <el-alert
          v-if="formTwitter.alert"
          :title="formTwitter.alert.text"
          :type="formTwitter.alert.type"
          :closable="false">
        </el-alert>

        <el-form-item label="Texto">
          <el-input
            type="textarea"
            :rows="6"
            v-model="formTwitter.plainSession"
            autocomplete="off" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal('Twitter')">Cerrar</el-button>
        <el-button type="primary" @click="saveSession('Twitter')">Guardar</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Agregar una nueva sesión de Tiktok" :visible.sync="dialogTiktokState" @close="closeModal('Tiktok')">
      <el-form :model="formTiktok">
        <el-alert
          v-if="formTiktok.alert"
          :title="formTiktok.alert.text"
          :type="formTiktok.alert.type"
          :closable="false">
        </el-alert>

        <el-form-item label="Texto">
          <el-input
            type="textarea"
            :rows="6"
            v-model="formTiktok.plainSession"
            autocomplete="off" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal('Tiktok')">Cerrar</el-button>
        <el-button type="primary" @click="saveSession('Tiktok')">Guardar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DatetimeFormat } from '@/filters/date-format'
import DatetimeFrom from '@/filters/datetime-from'
import Indicator from './_components/indicator'
import IndicatorAdvance from './_components/indicator_advance'

export default {
  name: 'Home',
  data () {
    return {
      stats: {
        tokens: {
          count: 0,
          updatedAt: null,
          subNumber: [
            {
              icon: 'normal',
              number: 0
            },
            {
              icon: 'frozen',
              number: 0
            }
          ]
        },
        tiktok: {
          count: 0,
          updatedAt: null,
          subNumber: [
            {
              icon: 'normal',
              number: 0
            },
            {
              icon: 'frozen',
              number: 0
            }
          ]
        },
        sessions: {
          count: 0,
          updatedAt: null
        },
        clients: {
          count: 0,
          updatedAt: null
        },
        projects: {
          count: 0,
          updatedAt: null
        },
        keywords: {
          count: 0,
          updatedAt: null
        },
        login: {
          data: null,
          updatedAt: null
        },
        created: {
          data: null,
          updatedAt: null
        }
      },
      tasks: {},
      floatingMenuState: false,
      dialogLinkedinState: false,
      formLinkedin: {
        alert: null,
        plainSession: null
      },
      dialogTwitterState: false,
      formTwitter: {
        alert: null,
        plainSession: null
      },
      dialogTiktokState: false,
      formTiktok: {
        alert: null,
        plainSession: null
      }
    }
  },
  methods: {
    async loadTokens () {
      try {
        const response = await this.$http.get('admin/tokens')

        this.stats.tokens.count = response.body.count
        const subProps = this.stats.tokens.subNumber.map(e => e.icon)
        console.log('subProps', subProps)
        this.stats.tokens.subNumber = []
        for (const key in response.body) {
          if (Object.hasOwnProperty.call(response.body, key)) {
            if (subProps.includes(key)) {
              const element = response.body[key]
              this.stats.tokens.subNumber.push({
                icon: key,
                count: element
              })
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loadTiktok () {
      try {
        const response = await this.$http.get('admin/tiktok')

        this.stats.tiktok.count = response.body.count
        const subProps = this.stats.tiktok.subNumber.map(e => e.icon)
        console.log('subProps', subProps)
        this.stats.tiktok.subNumber = []
        for (const key in response.body) {
          if (Object.hasOwnProperty.call(response.body, key)) {
            if (subProps.includes(key)) {
              const element = response.body[key]
              this.stats.tiktok.subNumber.push({
                icon: key,
                count: element
              })
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loadSessions () {
      try {
        const response = await this.$http.get('admin/linkedinSession')

        this.stats.sessions.count = response.body.count
      } catch (error) {
        console.log(error)
      }
    },
    async loadProjects () {
      try {
        const response = await this.$http.get('admin/activeprojects')

        this.stats.projects.count = response.body.count
      } catch (error) {
        console.log(error)
      }
    },
    async loadClients () {
      try {
        const response = await this.$http.get('admin/activeclients')

        this.stats.clients.count = response.body.count
      } catch (error) {
        console.log(error)
      }
    },
    async loadKeywords () {
      try {
        const response = await this.$http.get('admin/activekeywords')

        this.stats.keywords.count = response.body.total
      } catch (error) {
        console.log(error)
      }
    },
    async loadLogins () {
      try {
        const response = await this.$http.get('admin/lastlogin')

        this.stats.login.data = response.body
      } catch (error) {
        console.log(error)
      }
    },
    async loadcreated () {
      try {
        const response = await this.$http.get('admin/lastprojects?order=created')

        this.stats.created.data = response.body
      } catch (error) {
        console.log(error)
      }
    },
    async saveSession (mode) {
      const field = `form${mode}`
      try {
        this[field].alert = {
          type: 'info',
          text: 'Guardando...'
        }

        const result = await this.$http.post(
          `admin/${mode.toLowerCase()}/save`,
          {
            session: this[field].plainSession
          }
        )

        // console.log(result)

        if (result.data.status === 'ok') {
          if (mode === 'Twitter') {
            this.loadTokens()
          } else {
            this.loadSessions()
          }
          this[field].plainSession = null
          this[field].alert = {
            type: 'success',
            text: 'Sesión guardada con éxito'
          }
        } else {
          this[field].alert = {
            type: 'error',
            text: 'Error al guardar la sesión'
          }
        }
      } catch (error) {
        console.log(error)
        this[field].alert = {
          type: 'error',
          text: 'Error al guardar la sesión'
        }
      }
    },
    closeModal (mode) {
      this[`form${mode}`].alert = null
      this[`form${mode}`].plainSession = null
      this[`dialog${mode}State`] = false
    },
    refreshMe (key, task) {
      const vm = this
      task.timer = setInterval(() => {
        vm.stats[key].updatedAt = this.lastTime()
        task.callback()
      }, task.timeout * 60000) // Transform minute to miliseconds
    },
    async boot () {
      try {
        this.tasks = {
          tokens: {
            timeout: 5,
            callback: this.loadTokens,
            timer: null
          },
          tiktok: {
            timeout: 5,
            callback: this.loadTiktok,
            timer: null
          },
          sessions: {
            timeout: 5,
            callback: this.loadSessions,
            timer: null
          },
          clients: {
            timeout: 10,
            callback: this.loadClients,
            timer: null
          },
          projects: {
            timeout: 20,
            callback: this.loadProjects,
            timer: null
          },
          keywords: {
            timeout: 15,
            callback: this.loadKeywords,
            timer: null
          },
          login: {
            timeout: 6,
            callback: this.loadLogins,
            timer: null
          },
          created: {
            timeout: 7,
            callback: this.loadcreated,
            timer: null
          }
        }

        for (const key in this.tasks) {
          const task = this.tasks[key]
          task.callback()

          this.stats[key].updatedAt = this.lastTime()
          this.refreshMe(key, task)
        }
      } catch (error) {
        console.log(error)
      }
    },
    shutdown () {
      for (const key in this.tasks) {
        clearInterval(this.tasks[key].timer)
      }
    },
    lastTime () {
      const date = this.$options.filters.DatetimeFormat(new Date())
      return `<i class="el-icon-refresh"></i> ${date}`
    }
  },
  created () {
    this.boot()
  },
  beforeDestroy () {
    this.shutdown()
  },
  components: {
    Indicator,
    IndicatorAdvance
  },
  filters: {
    DatetimeFormat,
    DatetimeFrom
  }
}
</script>
<style lang="sass">
#home-container
  .floating-button
    // bottom: 2rem
    // position: fixed
    // right: 5rem

  .floating-container
    bottom: 2rem
    position: fixed
    right: 5rem
    height: 42px
    display: flex
    flex-direction: column-reverse
    overflow: hidden
    transition: .3s ease-in-out all
    z-index: 1

    .el-button
      &.floating-button-open
        z-index: 3
        transition: .3s ease-in-out all

      &.floating-button-icon
        padding: 9px 9px
        position: absolute
        bottom: 0

      &+.el-button
        margin-left: 0

    &.floating-open
      height: 200px
      width: 42px

      .el-button
        &.floating-button-open
          transform: rotate(45deg)

        &.floating-button-icon
          position: relative

        &+.el-button
          margin-bottom: 12px

  .el-row
    margin-bottom: 2.5rem

  h3
    small
      font-size: .7rem

      &:before
        content: '('

      &:after
        content: ')'
</style>
